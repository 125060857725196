import {ConfigProvider} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {AppContextContext} from "../Contexts";
import cs_CZ from "antd/lib/locale/cs_CZ";
import en_US from "antd/lib/locale/en_US";
import {observer} from "mobx-react-lite";
import LoginPage from "./LoginPage";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {routes} from "../routes";

const App = observer(() => {
    const appContext = useContext(AppContextContext);
    const [router, setRouter] = useState<any>();

    useEffect(() => {
        setRouter(createBrowserRouter(routes, {basename: (appContext.publicAccessUserId) ? `/p/${appContext.publicAccessUserId}` : '/'}));
    }, []);

    if (router === undefined) {
        return;
    }

    return (
        <ConfigProvider locale={(appContext.language === 'cs') ? cs_CZ : en_US}
                        theme={{
                            token: {
                                borderRadius: 2,
                                controlHeightLG: 48,
                                fontFamily: "'Open Sans', sans-serif",
                                fontSize: 13,
                                colorPrimary: "#0583FF",
                                lineHeight: 1.5,
                                colorText: "#434656",
                            },
                            components: {
                                Table: {
                                    bodySortBg: '#fff',
                                    rowExpandedBg: 'rgba(0, 0, 0, 0.02)',
                                    headerBg: '#515165',
                                    headerColor: '#fff',
                                    headerSortActiveBg: '#515165',
                                    headerSortHoverBg: '#515165'
                                }
                            }
                        }}>
            <div className="App">
                {appContext.user ? <RouterProvider router={router}/> : <LoginPage/>}
            </div>
        </ConfigProvider>
    );
});

export default App;